<template>
  <div class="container-fluid p-0">
    <b-alert :show="notification.showErrorCountDown" dismissible fade :variant="notification.type">
      {{ notificationMessage }}
    </b-alert>
    <h3><strong>Bulk</strong> Order Link Creator</h3>
    <div class="row">
      <div class="col-12 col-xl-6">
        <b-overlay :show="processing" rounded="sm" class="overlay-display">
          <template #overlay>
            <div class="text-center">
              <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
              <p id="cancel-label">Please wait...</p>
            </div>
          </template>

          <div class="row">
            <div class="col-auto">
              <p class="mt-3 mb-0">CVS file will be exported to Google Drive /Pull Folder/BulkOrderLinkCreator/</p>
              <p class="mt-3 mb-0">Order Ids Format: #11111,#22222,#33333</p>
              <p class="mt-3 mb-0">Delivery date will be 1 year later or selected date</p>
            </div>
          </div>

          <div class="form-card mt-3 mb-0 form">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <div>
                <div class="form-group">
                  <b-form-group>
                    <label class="mt-2 report-label">Order Ids</label>
                    <b-form-textarea id="textarea" v-model="form.orderIds" placeholder="Order Ids..." rows="3"
                      max-rows="100"></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="form-group">
                  <b-form-group>
                    <label class="mt-1 report-label">Delivery date: (If it's empty, will be last delivery date + 1
                      year)</label>
                    <b-form-datepicker :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }" v-model="form.deliveryDateNew" :date-disabled-fn="dateDisabled" class="mb-2"
                      required></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="row mt-3">
                  <div class="col-sm mt-2">
                    <b-form-group
                      label="Checkout Destination: ()"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio
                        v-model="form.cartType"
                        :aria-describedby="ariaDescribedby"
                        name="Callia.com Basket"
                        value="Callia"
                        >Callia.com Basket</b-form-radio
                      >
                      <b-form-radio
                        v-model="form.cartType"
                        :aria-describedby="ariaDescribedby"
                        name="Shopify Checkout"
                        value="Shopify"
                        >Shopify Checkout</b-form-radio
                      >
                    </b-form-group>
                  </div>
                </div>

                <b-button type="submit" variant="primary" :disabled="disabledButton"
                  class="form-submit-button submit-button adj-btn">Submit
                </b-button>
              </div>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </div>
    <div class="page-break"></div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

export default {
  data() {
    return {
      user: this.$store.getters.currentUser,
      email: "",
      initials: "",
      form: {
        orderIds: "",
        deliveryDate: "",
        deliveryDateNew: "",
        cartType: "Shopify"
      },
      show: true,
      processing: false,
      notification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage: ""
    };
  },
  async created() {
    this.user = this.$store.getters.currentUser;
    // console.log("user:", this.user);
    if (this.user) {
      this.email = this.user.email;
      // console.log("email:", this.email);
      const initials = this.user.name.split(" ").map((n, index) => {
        if (index == 0) {
          return n[0];
        } else {
          return n.substring(0, 2);
        }
      }).join("");
      this.initials = initials;
    }
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.processing = true;
      try {
        let deliveryDate = "";
        if (this.form.deliveryDateNew) {
          const deliveryDateObj = dayjs.tz(this.form.deliveryDateNew, 'America/Winnipeg');
          deliveryDate = deliveryDateObj.format("MM/DD/YYYY");
        }
        await this.axios.post(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
          "/bulk-order-link",
          {
            orderIds: this.form.orderIds,
            deliveryDate: deliveryDate,
            cartType: this.form.cartType,
            initials: this.initials,
            email: this.email
          }
        );

        this.notificationMessage = "Done. Check Google Drive";
        this.notification = {
          showErrorCountDown: 5,
          type: "success"
        };
        this.processing = false;
        this.onReset();
      } catch (error) {
        this.processing = false;
        console.log(error);
        this.notificationMessage = "Oopps, Something is not right !!!";
        this.notification = {
          showErrorCountDown: 5,
          type: "danger"
        };
      }
    },
    dateDisabled(ymd, date) {
      const dateSelected = dayjs.tz(ymd, 'America/Winnipeg');
      const today = dayjs().tz('America/Winnipeg').startOf('day');
      if (dateSelected.isBefore(today)) {
        return true;
      }
      return false;
    },
    onReset(event) {
      if (event) {
        event.preventDefault();
      }

      this.form.orderIds = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  computed: {
    disabledButton() {
      if (this.form.orderIds) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

.adj-btn {
  margin-top: 30px;
}

.btn-outline-primary {
  background-color: #fff !important;
  color: $callia-green !important;
}

.page-break {
  width: 100%;
  height: 52px;
}
</style>