<template>
<div>
    <section class="d-flex flex-row">
      <CLHeader mainTopic="Inventory Prediction" subTopic="Pipeline" />
      <b-button class="ml-2" variant="light" @click="refreshPage">
              <refresh-cw-icon size="1.5x" class="text-dark"></refresh-cw-icon>
      </b-button>
    </section>

    <section class="row justify-content-center">
      <CLHeader  mainTopic="Enter Pipeline" subTopic=" Parameters" />
    </section>

    <section class="d-flex flex-column flex-md-row align-items-center align-items-md-center mt-4 justify-content-center">
        <article>
             <b-dropdown id="dropdown-checkbox"
                    :text="dropdownText"
                    variant="primary"
                    class="m-2">
              <b-dropdown-item-button class="d-flex align-items-center">
                 <b-form-checkbox
                   id="select-all"
                   :checked="isAllSelected"
                   @change="toggleSelectAll">
                   Select All
                 </b-form-checkbox>
               </b-dropdown-item-button>
               <b-dropdown-item-button v-for="(option, index) in warehouseSKUOptions" :disabled="isAllSelected" :key="index" class="d-flex align-items-center">
                 <b-form-checkbox :id="'checkbox-' + index"  :checked="selectedWarehouseSKU.includes(option)"
                 @change="toggleOption(option)">
                   {{ option }}
                 </b-form-checkbox>
               </b-dropdown-item-button>
             </b-dropdown>
        </article>
        <article class="p-2">
                    <b-dropdown :text="selectedWeekCode||'Select WeekCode'" variant="primary" class=""  >
                      <b-dropdown-item v-for="option in weekCodeOptions" :key="option" :value="option" @click="setWeekCode(option)">{{ option }}</b-dropdown-item>
                    </b-dropdown>
        </article>
    </section>
    <section class="p-2 ml-4 row justify-content-center mt-6">
            <b-button :disabled='btnDisable' type="submit" size="lg" :variant="btnDisable?'secondary':'primary'" @click="submit">Submit</b-button>
    </section>

    <section class="row justify-content-center mt-6">
      <CLHeader  mainTopic="Request Pipeline" subTopic="Status" />
    </section>

    <section class="row justify-content-center mt-4">
        <b-form-input v-model="pipelineId" placeholder="Pipeline Id" type="text"></b-form-input>
        <article class="mt-4">
          <b-button  type="submit" size="lg" variant="primary" @click="openDrive">View Prediction</b-button>
          <b-button  type="submit" size="lg" variant="primary" @click="getPipelineStatus()" class="ml-2">Get Pipeline Status</b-button>
        </article>
    </section>
    <section class="row justify-content-center mt-4">
          <article v-if="isRequestedPipelineStatus && requestedPipelineStatus.pipelineId">
            <div class="row justify-content-center">
              <CLHeader  mainTopic="Pipeline" subTopic="Status" />
            </div>
            <div class="card text-center" :class="statusClass" >
              <div class="card-header" :class="statusClass">
                  <div class="d-flex justify-content-between align-items-center">
                        <b class="flex-grow-1 text-center">{{ requestedPipelineStatus.status.charAt(0).toUpperCase() + requestedPipelineStatus.status.slice(1) }}</b>
                        <XIcon @click="closeStatusTab" class="w-6 h-6 text-black cursor-pointer" />
                  </div>
              </div>
               <div class="card-body" :class="statusClass ">
                 <h5 class="card-title"><b>Pipeline Id - </b>  {{ requestedPipelineStatus.pipelineId  }}</h5>
                 <p class="card-text"><b>Prediction File Location - </b>{{   requestedPipelineStatus.fileName }}</p>
                 <p class="card-text"><b>{{ requestedPipelineStatus.warehouse_sku.split(',').length>1?`Warehouse's Selected -`:`Warehouse Selected -` }} </b>{{   requestedPipelineStatus.warehouse_sku }}</p>
                 <p class="card-text"><b>Week code selected - </b>{{   requestedPipelineStatus.weekCode }}</p>
                 <p class="card-text"><b>Created By - </b>{{   requestedPipelineStatus.user }}</p>
                 <b-button  type="submit" size="lg" variant="primary" @click="openPipeline(requestedPipelineStatus.webUrl)">View Pipeline</b-button>
               </div>
               <div class="card-footer text-muted" :class="statusClass">
                <b>Created At -</b> {{ requestedPipelineStatus.createdAt }}
               </div>
            </div>
          </article>
    </section>

    <section class="row justify-content-center mt-4" v-if="isLoading">
        <article class="text-center my-2">
            <b-spinner type="grow" variant="info" label="Loading..." class="align-middle"></b-spinner>
            <strong class="loadingLabel">Loading...</strong>
        </article>
    </section>

    <section v-if="isError" class="alert alert-danger" role="alert">
          {{ errorMessage }}
    </section>

    <section class="mt-6">
          <article class="row justify-content-center">
            <CLHeader mainTopic="Pipeline" subTopic="History" />
          </article>
          <article>
            <b-row class="mt-3">
                <b-col lg="2" sm="6" class="left pr-0 mr-0">
                  <h6 class="pagination-details">Per page
                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                  </h6>
                </b-col>
                <b-col sm="7" md="4" class="my-1 right-float">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"></b-pagination>
                </b-col>
            </b-row>
            <b-table
                 hover
                 :items="items"
                 :fields="fields"
                 striped
                 large
                 empty-text="No Data Available"
                 outlined
                 :busy="isBusy"
                 :current-page="currentPage"
                 responsive="sm"
                 :per-page="perPage"
                 class="table">
                 <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner type="grow" variant="info" label="Loading..." class="align-middle"></b-spinner>
                      <strong class="loadingLabel">Loading...</strong>
                    </div>
                 </template>
                  <template #cell(webUrl)="data">
                    <b-button :href="data.value" target="_blank" variant="primary" size="lg">View Pipeline</b-button>
                  </template>
            </b-table>
            <b-row class="mt-3">
                 <b-col lg="2" sm="6" class="left pr-0 mr-0">
                      <h6 class="pagination-details">Per page
                        <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                        </b-form-select>
                      </h6>
                 </b-col>
                 <b-col sm="7" md="4" class="my-1 right-float">
                     <b-pagination
                             v-model="currentPage"
                             :total-rows="totalRows"
                             :per-page="perPage"
                             :align="'fill'"
                             size="sm"
                             class="my-0">
                     </b-pagination>
                 </b-col>
            </b-row>
          </article>
    </section>
</div>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import { RefreshCwIcon,XIcon } from "vue-feather-icons";
import { CLHeader, } from "callia-ui-box";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
export default {
    data(){
        return{
           warehouseSKUOptions: ['cg','tr','mt','mb','sk','ab','bc','hx'],
           selectedWarehouseSKU: [],
           weekCodeOptions:[],
           selectedWeekCode:'',
           isError:false,
           requestedPipelineStatus:{},
           isRequestedPipelineStatus:false,
           isLoading:false,
           errorMessage:'',
           user:'',
           items:[],
           pipelineId:'',
           fields: [
             {
               key: "pipelineId",
               sortable: true,
               label: "Pipeline Id",
             },
             {
               key: "status",
               sortable: true,
               label: "Status",
             },
             {
               key: "weekCode",
               sortable: true,
               label: "Week Code",
             },
             {
               key: "warehouse_sku",
               sortable: true,
               label: "Warehouse SKU",
             },
             {
               key: "finetuning",
               label: "Finetuning",
             },
             {
               key: "fileName",
               sortable: true,
               label: "File Location",
             },
             {
               key: "user",
               sortable: true,
               label: "User",
             },
             {
               key: "createdAt",
               sortable: true,
               label: "Created At",
             },
             {
               key: "webUrl",
               sortable: true,
               label: "web Url",
             },
           ],
           perPage: 5,
           pageOptions: [5, 10, 15, {value: 100, text: "Show More"}],
           totalRows: 1,
           isBusy: true,
           currentPage: 1,
    }
    },
    components:{RefreshCwIcon,CLHeader,XIcon},
    computed: {
       btnDisable() {
           return !(
               this.selectedWarehouseSKU &&
               this.selectedWeekCode
           )
        },
       isAllSelected() {
         return this.selectedWarehouseSKU.length === this.warehouseSKUOptions.length;
       },
       dropdownText() {
         return this.selectedWarehouseSKU.length ? this.selectedWarehouseSKU.join(', ') : 'Select Warehouse SKU';
       },
       statusClass() {
         return {
           'border-danger ': this.requestedPipelineStatus.status === 'canceled' || this.requestedPipelineStatus.status === 'failed',
           'border-success': this.requestedPipelineStatus.status === 'success',
           'border-secondary': this.requestedPipelineStatus.status === 'created'
         };
       }
   },
  async created(){
    this.user=JSON.parse(sessionStorage['okta-token-storage']).accessToken.claims.sub;
    // this.getWarehouseDetails();
    this.getTriggeredPipelineHistory();
    this.generateWeekCodes();
   },

methods: {
    toggleSelectAll() {
      this.selectedWarehouseSKU=this.isAllSelected?[]:[...this.warehouseSKUOptions];
    },
    toggleOption(option) {
      if (this.selectedWarehouseSKU.includes(option)) {
        this.selectedWarehouseSKU = this.selectedWarehouseSKU.filter(item => item !== option);
      } else {
        this.selectedWarehouseSKU.push(option);
      }
    },
    setWeekCode(value) {
      this.selectedWeekCode = value;
    },
    async submit() {
        if(this.selectedWarehouseSKU && this.user && this.selectedWeekCode){
        try{
           const data = {
             warehouseSKU: this.selectedWarehouseSKU.join(','),
             finetuning: "no",
             user:this.user,
             weekCode:this.selectedWeekCode
           };
            this.isLoading=true;
            const res=  await this.axios.post(`${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/api/trigger-pipeline`,data);
            const pipelineData=res.data.data;
            this.pipelineId=pipelineData.pipeline_id;
            await this.getPipelineStatus();
            this.getTriggeredPipelineHistory();
            this.isLoading=false;
        }
        catch(err){
            this.isError=true
            this.isLoading=false
            this.errorMessage='Error in triggering Pipeline,'+err.message;
            console.log(err)
       }
      }
    },
    openPipeline(pipelineUrl) {
      window.open(pipelineUrl, '_blank');
    },
    openDrive(){
      window.open('https://drive.google.com/drive/folders/1q6yM-wdas3caPud7EHmS6YUQM5ookdpj','_blank')
    },
    async getTriggeredPipelineHistory(){
      try {
        this.isBusy = true;
        const response =  await this.axios.get(`${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/api/trigger-pipeline/all`);
        if(response.status===400){
          throw new Error(response.data);
        }
        this.items=response.data;
        this.totalRows = this.items.length;
        this.isBusy = false;
      } catch (error) {
        this.isError=true
        console.error('error',error)
        this.errorMessage=error.message;
      }
    },
    async getPipelineStatus(){
      try {
        if(this.pipelineId){
          this.isLoading=true;
          const response =  await this.axios.get(`${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/api/trigger-pipeline/${this.pipelineId}`);
          if(response.status===400){
          throw new Error(response.data);
          }
          this.requestedPipelineStatus=response.data;
          this.isRequestedPipelineStatus=true;
          this.isLoading=false;
        }
      } catch (error) {
            console.error('error',error)
            this.isError=true
            this.isLoading=false
            this.errorMessage='Error in triggering Pipeline, '+error.message;
      }
    },
    // async getWarehouseDetails() {
    //   try {
    //     const response = await this.axios({
    //       method: "GET",
    //       url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/warehouse`,
    //       headers: {
    //         Authorization: `Bearer ${this.$store.getters.idToken}`
    //       }
    //     });
    //     const provinceArray = response.data;
    //     provinceArray.forEach(qData => {
    //     //  const warehouseName = `${qData.name}-${qData.sku}`;
    //       this.warehouseSKUOptions.push(qData.sku);
    //     });
    //   } catch (errorResponse) {
    //     console.log("Error: ", errorResponse);
    //   }
    // },
    generateWeekCodes() {
      const weekCodes = [];
      let currentDate = dayjs();

      for (let i = 0; i < 5; i++) {
        let weekNumber = currentDate.isoWeek().toString();
        weekNumber = weekNumber < 10 ? `0${weekNumber}` : weekNumber;
        const yearSuffix = currentDate.isoWeekYear().toString().slice(-2);
        weekCodes.push(`${weekNumber}${yearSuffix}`);

        currentDate = currentDate.add(1, 'week');
  }
     this.weekCodeOptions=weekCodes
    },
    refreshPage(){
      this.isLoading=true;
      this.selectedWarehouseSKU=[];
      this.selectedWeekCode='';

      this.pipelineId='';
      this.requestedPipelineStatus={};
      this.isRequestedPipelineStatus=false;
      this.getTriggeredPipelineHistory();
      this.isLoading=false;
      this.isError=false;

    },
    closeStatusTab(){
      this.pipelineId='';
      this.requestedPipelineStatus={};
      this.isRequestedPipelineStatus=false;
    }
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/scss/cutoff-time-grid.scss";
</style>
