<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50">
    <div class="max-w-md w-full space-y-8">
      <div class="text-center">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
        <p class="mt-4 text-gray-600 text-lg">Authenticating...</p>
        <p class="mt-2 text-gray-500 text-sm">Please wait while we verify your session</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginTransit',
  data() {
    return {
      show: true,
      error: null
    };
  },
  async created() {
    try {
      await this.$auth.handleLoginRedirect();
      if (await this.$auth.isAuthenticated()) {
        const user = await this.$auth.getUser();
        this.$router.push("/secure/" + user.sub + "/dashboard");
      } else {
        this.$router.push("/login");
      }
    } catch (error) {
      console.error('Authentication error:', error);
      this.error = 'Failed to authenticate. Please try again.';
      // Redirect to login after a short delay
      setTimeout(() => {
        this.$router.push("/login");
      }, 2000);
    }
  }
};
</script>

<style scoped>
.overlay-display {
  margin-top: 20%;
}
</style>
