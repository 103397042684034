<template>
  <div v-if="show" class="token-warning-container">
    <div class="warning-content">
      <div class="warning-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
      </div>
      <span class="countdown-text"
        >Your session will expire in {{ formatTimeLeft }}</span
      >
      <div class="button-group">
        <button
          @click="handleRefresh"
          type="button"
          class="refresh-button"
          :disabled="isRefreshing"
        >
          <svg
            v-if="isRefreshing"
            class="animate-spin -ml-1 mr-2 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {{ isRefreshing ? "Refreshing..." : "Refresh Session" }}
        </button>
        <button
          @click="dismiss"
          type="button"
          class="dismiss-button"
          :disabled="isRefreshing"
        >
          Dismiss
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TokenExpirationWarning",
  props: {
    timeLeft: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      show: true,
      isRefreshing: false,
      currentTimeLeft: 0,
      countdownInterval: null
    };
  },
  computed: {
    formatTimeLeft() {
      const totalSeconds = Math.ceil(this.currentTimeLeft / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    }
  },
  watch: {
    timeLeft: {
      immediate: true,
      handler(newValue) {
        this.currentTimeLeft = newValue;
        this.startCountdown();
      }
    }
  },
  methods: {
    startCountdown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      // Start new countdown
      this.countdownInterval = setInterval(() => {
        if (this.currentTimeLeft <= 0) {
          clearInterval(this.countdownInterval);
          // Force logout when time reaches 0
          window.location.href = "/";
          return;
        }
        this.currentTimeLeft = Math.max(0, this.currentTimeLeft - 1000);
      }, 1000);
    },
    dismiss() {
      this.show = false;
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
      this.$emit("dismiss");
    },
    async handleRefresh() {
      if (this.isRefreshing) return;

      this.isRefreshing = true;
      try {
        // Emit refresh-session event and wait for the result
        const result = await this.$emit("refresh-session");

        // Reset the countdown timer with new expiration time
        if (result && result.newExpirationTime) {
          this.currentTimeLeft = result.newExpirationTime;
          this.startCountdown();
        }

        this.show = false;

        window.location.reload();
      } catch (error) {
        console.error("Failed to refresh session:", error);
        this.$emit("refresh-error", error);
      } finally {
        this.isRefreshing = false;
      }
    }
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }
};
</script>

<style scoped>
.token-warning-container {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 0 1.5rem;
  border-left: 1px solid #e9ecef;
}

.warning-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #dc2626;
  font-weight: 500;
}

.warning-icon {
  display: flex;
  align-items: center;
  color: #dc2626;
}

.warning-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}

.countdown-text {
  white-space: nowrap;
  font-size: 0.875rem;
}

.button-group {
  display: flex;
  gap: 0.75rem;
}

.refresh-button,
.dismiss-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  transition: all 0.2s;
}

.refresh-button {
  background-color: #dc2626; /* Red background */
  color: white; /* White text */
}

.refresh-button:hover:not(:disabled) {
  background-color: #b91c1c; /* Darker red on hover */
}

.dismiss-button {
  background-color: transparent;
  color: #dc2626;
  border: 1px solid #dc2626;
}

.dismiss-button:hover:not(:disabled) {
  background-color: rgba(220, 38, 38, 0.1);
}

.refresh-button:disabled,
.dismiss-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
