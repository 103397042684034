// eslint-disable-next-line @typescript-eslint/no-var-requires
const axios = require("axios");
const graphQLEndpoint = "https://checkout.callia.com/api/2025-01/graphql.json";
const accessToken = "7704488aef4e017ab481754b92acd59f";

const stringFormat = (string, ...args) => {
  return string.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

/**
 * GraphQL executor
 */
const invokeShopifyQuery = async (request) => {
  const { data } = await axios
    .post(graphQLEndpoint, request, {
      headers: {
        "X-Shopify-Storefront-Access-Token": accessToken,
        Accept: "application/json",
        "Content-Type": "application/graphql",
      },
    })
    .catch(function (error) {
      console.error(error);
    });
  return data;
};

const getDeliveryProducts = async() => {
  const request = `query {  products (first: 3, query:"product_type:Delivery" ) {
      edges {  node {    id description availableForSale title tags productType handle
      images(first:5){ edges{ node{  id originalSrc } } }
      priceRange{ maxVariantPrice{ amount currencyCode }
        minVariantPrice{amount currencyCode } }
      variants(first:100){ edges{ node{          id availableForSale sku priceV2{amount } product{description title productType id} }} }
      } }  } }`;
  const { data } = await axios
    .post(graphQLEndpoint, request, {
      headers: {
        "X-Shopify-Storefront-Access-Token": accessToken,
        Accept: "application/json",
        "Content-Type": "application/graphql",
      },
    })
    .catch(function (error) {
      console.error(error);
    });
  console.log(`data:`, data);
  return data;
}

/**
 * Query
 */
const itemFilterWithActive = (tag, productType) => {
  const ITEM_FILTER_WITH_ACTIVE = `query {
      products (first: 25, query:"tag:${tag} AND tag:active AND product_type:${productType} AND available_for_sale:true" ) {
        edges {  node {
            id description availableForSale title tags handle
          images(first:10){ edges{ node{ id originalSrc } } }
            priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
            variants(first:10){ edges{ node{
        id availableForSale sku priceV2 { amount currencyCode } compareAtPriceV2 {amount currencyCode} product{description title productType id}  }} }
        } }
      } }`;

  return ITEM_FILTER_WITH_ACTIVE;
};

const testSubsQuery = (tag, productType) => { //TODO: remove
  const ITEM_FILTER_WITH_ACTIVE = `query {
      products (first: 5, query:"tag:${tag}" ) {
        edges {  node {
            id description availableForSale title tags handle
          images(first:10){ edges{ node{ id originalSrc } } }
            priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
            variants(first:45){ edges{ node{
        id availableForSale sku priceV2 { amount currencyCode } compareAtPriceV2 {amount currencyCode} product{description title productType id}  }} }
        } }
      } }`;

  return ITEM_FILTER_WITH_ACTIVE;
};


const itemfilterWithCharity = (region, productType) => {
  const ITEM_FILTER_WITH_CHARITY = `query {
    products (first: 25, query:"tag:${region} AND product_type:${productType} AND tag:charity AND available_for_sale:true" ) {
      edges {  node {
        id description availableForSale title tags handle
        images(first:10){ edges{ node{ id originalSrc } } }
        priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
        variants(first:10){ edges{ node{ id availableForSale sku priceV2 { amount currencyCode } product{description title productType id}  }} }
      } }
    } }`;
  return ITEM_FILTER_WITH_CHARITY;
};

const itemFilterWithGreets = () => {
  const VIDEO_MESSAGE_ITEM_FILTER = `query { products (first: 30, query:"sku:greet AND tag:greet AND tag:gift-adds" ) {
    edges {  node {  id description availableForSale title tags handle images(first:10){ edges{ node{ id originalSrc } } }
      priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
      variants(first:10){ edges{ node{ id availableForSale sku compareAtPriceV2 {amount currencyCode} priceV2{amount } product{description title productType id}  }} }
    } } } }`;

  return VIDEO_MESSAGE_ITEM_FILTER;
};

const filterProductByHandle = (sku) => {
  const FILTER_PRODUCT_BY_HANDLE = `{productByHandle(handle:"${sku}") {
    id title tags handle productType
    variants(first:45){ edges{ node{
    id availableForSale quantityAvailable compareAtPriceV2 {amount currencyCode} title sku priceV2 { amount currencyCode } image{ id originalSrc transformedSrc } product{description title productType id }}}}
    images(first:10){ edges{ node{ id originalSrc  transformedSrc }}}}}`;

  return FILTER_PRODUCT_BY_HANDLE;
};

const filterProductDetails = (id) => {
  const FILTER_PRODUCT_DETAILS = `{ node(id:"${id}"){
    ... on Product {
    id title tags handle productType
    variants(first:45){ edges{ node{ id quantityAvailable availableForSale title sku priceV2 { amount currencyCode } compareAtPriceV2 {amount currencyCode} product{title productType id}}}}
    }}}`;

  return FILTER_PRODUCT_DETAILS;
};

const getDeliveryProductByHandle = (deliveryProductType) => {
  const DELIVERY_CHARGE = `query {  products (first: 3, query:"product_type:${deliveryProductType}" ) {
    edges {  node {    id description availableForSale title tags productType handle
    images(first:5){ edges{ node{  id originalSrc } } }
    priceRange{ maxVariantPrice{ amount currencyCode }
      minVariantPrice{amount currencyCode } }
  variants(first:100){ edges{ node{          id availableForSale sku priceV2{amount } product{description title productType id} }} }
  } }  } }`;
  return DELIVERY_CHARGE;
};

const getProductsWithProductType = (productType, tag) => {
  const PRODUCTS_FILTER_WITH_PRODUCT_TYPE = `query {
    products (first: 20, query:"tag:${tag} AND product_type:${productType} AND available_for_sale:true" ) {
    edges {  node {
      id availableForSale title tags
      priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
      variants(first:10){ edges{ node{ title sku compareAtPriceV2 {amount currencyCode} priceV2 { amount currencyCode } availableForSale
      product{title productType id} }} }
      } }
    } }`;

  return PRODUCTS_FILTER_WITH_PRODUCT_TYPE;
};

const getSubscriptionsWithTag = (
  productType,
  mainTag,
  subTag
) => {
  const SUBSCRIPTION_FILTER_WITH_TAGS = `query {
    products (first: 20, query:"tag:${mainTag} AND tag:${subTag} product_type:${productType} AND available_for_sale:true" ) {
    edges {  node {
      id availableForSale title tags
      priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
      variants(first:10){ edges{ node{ title sku compareAtPriceV2 {amount currencyCode} priceV2 { amount currencyCode } availableForSale
      product{title productType id} }} }
    } }
    } }`;

  return SUBSCRIPTION_FILTER_WITH_TAGS;
};

const itemFilterWithSubscriptionAddons = (
  productType,
  mainTag,
  subTag
) => {
  const SUBS_ADDON_ITEM_FILTER = `query { products (first: 30, query:"tag:${mainTag} AND tag:${subTag} product_type:${productType} AND available_for_sale:true" ) {
    edges {  node {  id description availableForSale title tags handle images(first:10){ edges{ node{ id originalSrc } } }
      priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
      variants(first:10){ edges{ node{ id availableForSale sku title compareAtPriceV2 {amount currencyCode} priceV2{amount } product{description title productType id}  }} }
    } } } }`;

  return SUBS_ADDON_ITEM_FILTER;
};

const FilterWithProductTypeOnly = (country, productType) => {
  const PRODUCTS_FILTER_WITHPRODUCT_TYPE_ONLY = `query {
    products (first: 20, query:"tag:${country} AND product_type:${productType} AND available_for_sale:true" ) {
      edges {  node {
          id availableForSale title tags
          priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
          variants(first:10){ edges{ node{ title sku compareAtPriceV2 {amount currencyCode} priceV2 { amount currencyCode } availableForSale
          product{title productType id} }} }
      } }
    } }`;
  return PRODUCTS_FILTER_WITHPRODUCT_TYPE_ONLY;
};

const FilterBouquetsLandingProducts = (dateCode, productType) => {
  const BOUQUET_LANDING_PRODUCTS_FILTER = `query {
    products (first: 20, query:"tag:LANDING AND tag:${dateCode} AND product_type:${productType} AND available_for_sale:true") {
    edges {  node {
      id handle availableForSale title tags
      priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
      variants(first:10){ edges{ node{ title sku compareAtPriceV2 {amount currencyCode} priceV2 { amount currencyCode } availableForSale
      product{title productType id} }} }
      } }
    } }`;
  return BOUQUET_LANDING_PRODUCTS_FILTER;
};
const FilterBundleLandingProducts = (dateCode, productType) => {
  const BUNDLE_LANDING_PRODUCTS_FILTER = `query {
    products (first: 10, query:"tag:LANDING AND tag:${dateCode} AND product_type:${productType}" ) {
    edges {  node {
      id handle availableForSale title tags
      priceRange{ maxVariantPrice{ amount currencyCode } minVariantPrice{amount currencyCode } }
      variants(first:10){ edges{ node{ title sku compareAtPriceV2 {amount currencyCode} priceV2 { amount currencyCode } availableForSale
      product{title productType id} }} }
      } }
    } }`;
  return BUNDLE_LANDING_PRODUCTS_FILTER;
};

const invokeShopifyCheckoutCreate = async (
  lineItemsRequest,
  currencyCode
) => {
  const request = {
    query: `mutation cartCreateMutation($cartInput: CartInput) {
      cartCreate(input: $cartInput) {
        cart {
          id
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }`,
    variables: lineItemsRequest
  };
  console.log(`request: ${JSON.stringify(request, null, 2)}`);
  const { data } = await axios
    .post(graphQLEndpoint, request, {
      headers: {
        "X-Shopify-Storefront-Access-Token": accessToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      console.error(error);
    });
  console.log(`data:`, data);
  return data;
};

module.exports = {
  getDeliveryProducts,
  invokeShopifyQuery,
  invokeShopifyCheckoutCreate,
  itemFilterWithActive,
  itemFilterWithGreets,
  filterProductByHandle,
  filterProductDetails,
  getDeliveryProductByHandle,
  getProductsWithProductType,
  getSubscriptionsWithTag,
  FilterWithProductTypeOnly,
  itemfilterWithCharity,
  itemFilterWithSubscriptionAddons,
  FilterBouquetsLandingProducts,
  FilterBundleLandingProducts,
};
