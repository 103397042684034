import { OktaAuth } from "@okta/okta-auth-js";

let _oktaAuth;

export const navigationGuard = async (to, from, next) => {
  if (
    to.matched.some(record => record.meta.noAuth) ||
    (await _oktaAuth.isAuthenticated())
  ) {
    // if auth ignore or authenticated then proceed
    next();
  } else {
    // redirect to /
    next({ path: "/" });
  }
};

export const getCurrentUser = async () => {
  if (await _oktaAuth.isAuthenticated()) {
    return await _oktaAuth.getUser();
  } else {
    return null;
  }
};

function install(
  Vue,
  {
    clientId,
    issuer,
    redirectUri,
    scopes,
    pkce,
    tokenManager,
    originalUri,
    idp,
    logoutRedirectUri
  }
) {
  const oktaAuth = new OktaAuth({
    clientId,
    issuer,
    redirectUri,
    scopes,
    pkce,
    tokenManager
  });
  _oktaAuth = oktaAuth;

  const auth = { oktaAuth };

  auth.signIn = async () => {
    await oktaAuth.signInWithRedirect({
      originalUri,
      idp
    });
  };

  auth.signOut = async () => {
    await oktaAuth.signOut({ postLogoutRedirectUri: logoutRedirectUri });
  };

  auth.handleLoginRedirect = async () => {
    if (oktaAuth.isLoginRedirect()) {
      try {
        await oktaAuth.handleLoginRedirect();
      } catch (e) {
        console.error("login redirection failed cause", e);
      }
    }
  };

  auth.isAuthenticated = async () => {
    return await oktaAuth.isAuthenticated();
  };

  auth.getUser = async () => {
    if (await oktaAuth.isAuthenticated()) {
      return await oktaAuth.getUser();
    } else {
      return null;
    }
  };

  auth.getTokens = () => {
    return {
      idToken: oktaAuth.getIdToken(),
      accessToken: oktaAuth.getAccessToken()
    };
  };

  auth.handleSignInWithRedirect = async () => {
    if (oktaAuth.isLoginRedirect()) {
      try {
        await oktaAuth.handleLoginRedirect();
      } catch (e) {
        console.error("handleLoginRedirect failed cause", e);
      }
    } else if (!(await oktaAuth.isAuthenticated())) {
      await oktaAuth.signInWithRedirect({
        originalUri,
        idp
      });
    }
  };

  Vue.prototype.$auth = auth;

  console.log("init-okta-auth");
}

export default { install };
