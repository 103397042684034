// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Vue from "vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Vuex from "vuex";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VueAxios from "vue-axios";
import router from "./routes";
import { getCurrentUser } from "./callia-okta-authenticate";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

interface TokenExpirationWarning {
  show: boolean;
  timeLeft: number;
}

export const store = new Vuex.Store({
  state: {
    currentUser: null,
    accessToken: "",
    idToken: "",
    isAuthenticated: false,
    globalException: {
      displayFor: 2,
      error: ""
    },
    tokenExpirationWarning: {
      show: false,
      timeLeft: 0
    } as TokenExpirationWarning,
    tokenCheckInterval: undefined as number | undefined,
    todayOnfleetDrivers: [],
    onfleetDriverSMSTemplate: [],
    regions: [],
    giftsetsalert: [],
    postalCodes: [],
    zone: [],
    deliveryRates: [],
    town: [],
    province: [],
    deliverySMSTemplate: [],
    giftsetAlertTemplate: []
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
    accessToken(state) {
      return state.accessToken;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    idToken(state) {
      return state.idToken;
    },
    tokenExpirationWarning(state) {
      return state.tokenExpirationWarning;
    },
    regions(state) {
      return state.regions;
    },
    giftsetsalert(state) {
      return state.giftsetsalert;
    },
    todayOnfleetDrivers(state) {
      return state.todayOnfleetDrivers;
    },
    onfleetDriverSMSTemplate(state) {
      return state.onfleetDriverSMSTemplate;
    },
    postalCodes(state) {
      return state.postalCodes;
    },
    zone(state) {
      return state.zone;
    },
    deliveryRates(state) {
      return state.deliveryRates;
    },
    town(state) {
      return state.town;
    },
    province(state) {
      return state.province;
    },
    deliverySMSTemplate(state) {
      return state.deliverySMSTemplate;
    },
    giftsetAlertTemplate(state) {
      return state.giftsetAlertTemplate;
    }
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setIdToken(state, idToken) {
      state.idToken = idToken;
    },
    setRegions(state, regions) {
      state.regions = regions;
    },
    setGiftSetsAlert(state, giftsetsalert) {
      state.giftsetsalert = giftsetsalert;
    },
    setTodayOnfleetDrivers(state, todayOnfleetDrivers) {
      state.todayOnfleetDrivers = todayOnfleetDrivers;
    },
    setOnfleetDriverSMSTemplate(state, onfleetDriverSMSTemplate) {
      state.onfleetDriverSMSTemplate = onfleetDriverSMSTemplate;
    },
    setPostalCodes(state, postalCodes) {
      state.postalCodes = postalCodes;
    },
    setZone(state, zone) {
      state.zone = zone;
    },
    setDeliveryRates(state, deliveryRates) {
      state.deliveryRates = deliveryRates;
    },
    setTown(state, town) {
      state.town = town;
    },
    setProvince(state, province) {
      state.province = province;
    },
    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setDeliverySMSTemplate(state, SMSTemplate) {
      state.deliverySMSTemplate = SMSTemplate;
    },
    setGiftsetAlertTemplate(state, giftsetAlertTemplate) {
      state.giftsetAlertTemplate = giftsetAlertTemplate;
    },
    SET_TOKEN_WARNING(state, { show, timeLeft }: TokenExpirationWarning) {
      state.tokenExpirationWarning = { show, timeLeft };
    },
    SET_TOKEN_CHECK_INTERVAL(state, interval: number | undefined) {
      state.tokenCheckInterval = interval;
    }
  },
  actions: {
    async getCurrentuser(context) {
      if (!context.state.currentUser) {
        const user = await getCurrentUser();
        if (user) {
          context.commit("setCurrentUser", user);
        } else {
          console.log("can't get token");
        }
      }
    },

    async setAuthContext(context, payload) {
      const { tokens, currentUser } = payload;
      const { idToken, accessToken } = tokens;
      context.commit("setIdToken", idToken);
      context.commit("setAccessToken", accessToken);
      context.commit("setCurrentUser", currentUser);
      context.commit("setAuthenticated", true);
    },

    async logout(context) {
      context.commit("setIdToken", null);
      context.commit("setAccessToken", null);
      context.commit("setCurrentUser", null);
      context.commit("setAuthenticated", false);
      // Stop token expiration check on logout
      context.dispatch("stopTokenExpirationCheck");
    },

    startTokenExpirationCheck({ commit, state }, { oktaAuth }) {
      // Clear any existing interval
      if (state.tokenCheckInterval) {
        clearInterval(state.tokenCheckInterval);
      }

      // Check token expiration every minute
      const interval = setInterval(async () => {
        try {
          const accessToken = oktaAuth.getAccessToken();
          if (!accessToken) return;

          const token = await oktaAuth.tokenManager.get("accessToken");
          if (!token) return;

          const expiresAt = token.expiresAt * 1000; // Convert to milliseconds
          const now = Date.now();
          const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds
          const timeLeft = expiresAt - now;

          // If token has expired, logout user
          if (timeLeft <= 0) {
            await oktaAuth.signOut({
              postLogoutRedirectUri: window.location.origin
            });
            return;
          }

          // If token expires in less than 5 minutes
          if (timeLeft <= fiveMinutes) {
            commit("SET_TOKEN_WARNING", { show: true, timeLeft });
          } else {
            commit("SET_TOKEN_WARNING", { show: false, timeLeft: 0 });
          }
        } catch (error) {
          console.error("Error checking token expiration:", error);
          // If there's an error checking token, logout user
          await oktaAuth.signOut({
            postLogoutRedirectUri: window.location.origin
          });
        }
      }, 60000); // Check every minute

      commit("SET_TOKEN_CHECK_INTERVAL", interval);
    },

    stopTokenExpirationCheck({ state, commit }) {
      if (state.tokenCheckInterval) {
        clearInterval(state.tokenCheckInterval);
        commit("SET_TOKEN_CHECK_INTERVAL", undefined);
      }
      commit("SET_TOKEN_WARNING", { show: false, timeLeft: 0 });
    },

    async refreshToken({ commit }, { oktaAuth }) {
      try {
        await oktaAuth.tokenManager.renew("accessToken");
        commit("SET_TOKEN_WARNING", { show: false, timeLeft: 0 });
        return true;
      } catch (error) {
        console.error("Failed to refresh token:", error);
        await oktaAuth.signOut({
          postLogoutRedirectUri: window.location.origin
        });
        return false;
      }
    }
  }
});
