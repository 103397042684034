<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="" subTopic="Delivery Rate" />
          <CLButton format="primary-btn" v-on:func="showAddModal">
            <plus-icon size="1.5x" class="text-white"></plus-icon>
            Add New
          </CLButton>
          <CLButton
            class="ml-1"
            format="secondary-btn"
            :disabled="isUpdateDisabled"
            v-on:func="updateDeliveryRate"
          >
            Update All Changes
          </CLButton>
          <b-button class="ml-1" variant="light" @click="refreshPage">
            <refresh-cw-icon size="1.5x" class="text-dark"></refresh-cw-icon>
          </b-button>
          <add-DeliveryRate-popup
            ref="AddDeliveryRateModal"
            :deliveryRateOptions="deliveryRateOptions"
            @success="refreshGrid($event)"
            @error="errorCreateDeliveryRate"
            @changeProvince="loadRegionCMBByProvinceID($event)"
            @changeRegion="loadZoneCMBByRegionID($event)"
          />
        </div>
      </div>
      <div class="row mb-0">
        <DeliveryRate-grid
          :pgCurrentPage="pgCurrentPage"
          :pgTotalRows="pgTotalRows"
          :pgPerPage="pgPerPage"
          :processing="processing"
          :rowData="rowData"
          :columnDefs="columnDefs"
          @updateRowArray="setUpdateRowArray($event)"
          @deleteDeliveryRate="deleteDeliveryRate($event)"
          @gridPaginationEvent="gridPaginationEvent($event)"
          @gridPaginationPerPageChange="updatePgPerPageChanges($event)"
          @changePaginationPageEvent="changePgCurrentPage($event)"
          @filterDataTableEvent="filterDeliveryRateData($event)"
        ></DeliveryRate-grid>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon, RefreshCwIcon } from "vue-feather-icons";
import DeliveryRateGrid from "@/components/grids/delivery-rate-grid";
import { CLHeader, CLButton } from "callia-ui-box";
import AddDeliveryRateModal from "@/components/delivery-rate/add-delivery-rate-popup";

export default {
  components: {
    PlusIcon,
    RefreshCwIcon,
    "DeliveryRate-grid": DeliveryRateGrid,
    "add-DeliveryRate-popup": AddDeliveryRateModal,
    CLHeader,
    CLButton,
  },
  data() {
    return {
      totalRowData: [],
      totalRowDataNotFilter: [],
      pgCurrentPage: 1,
      pgTotalRows: 100,
      pgPerPage: 20,
      putRequestArray: [],
      rowData: [],
      processing: false,
      zoneOptions: [],
      deliveryRateOptions: [],
      columnDefs: [],
      provinceOptions: [],
      regionOptions: [],
      tblDeliveryRateOptions: [],
      tblZoneOptions: [],
      isUpdateDisabled: true,
      applicableDateOptions: [
        { value: 0, label: "0" },
        { value: 1, label: "1" },
      ],

      isSameDayOnlyOptions: [
        { value: true, label: "true" },
        { value: false, label: "false" },
      ],
      tblApplicableDateOptions: [
        { value: 0, text: "0" },
        { value: 1, text: "1" },
      ],

      tblIsSameDayOnlyOptions: [
        { value: true, text: "true" },
        { value: false, text: "false" },
      ],
    };
  },
  async created() {
    this.processing = true;
    await this.getAllDeliveryRates();
    await this.initializeEditableTable();
    this.processing = false;
    this.putRequestArray = [];
  },
  methods: {
    async filterDeliveryRateData(filterData) {
      console.log("filterData", filterData)
      //check filter data array filterOn empty stage
      this.processing = true;
      this.totalRowData = this.totalRowDataNotFilter.filter((item) => {
        if (
          (filterData.filterOn.includes("name") && item.name.includes(filterData.searchString))
        ) {
          return true;
        } else {
          return false;
        }
      });
      this.rowData = this.totalRowData.slice(0, this.pgPerPage);
      this.loopRowdata();

      this.pgCurrentPage = 1;
      this.processing = false;
    },
    changePgCurrentPage(currentPage) {
      this.pgCurrentPage = currentPage;
    },
    updatePgPerPageChanges(perPage) {
      this.processing = true;
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(perPage);
      this.getAllDeliveryRates();
      this.processing = false;
    },
    gridPaginationEvent(currentPage) {
      this.getAllDeliveryRatesByPagination(currentPage);
    },
    async refreshPage() {
      this.isUpdateDisabled = true;
      this.processing = true;
      this.putRequestArray.length = 0;
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(this.pgPerPage);
      await this.getAllDeliveryRates();
      this.processing = false;
    },
    async loadDeliveryRateCMB() {
      try {
        const deliveryRatesArray = this.$store.getters.deliveryRates;
        deliveryRatesArray.map((qData) => {
          this.deliveryRateOptions.push({ value: qData.id, label: qData.name });
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async initializeEditableTable() {
      /** Initialize DataTable */
      try {
        const displayActionButton = (event) => {
          return "Delete";
        };
        this.tblZoneOptions = [];
        this.$store.getters.zone.map((qData) => {
          this.tblZoneOptions.push({
            value: qData.zone_name,
            text: qData.zone_name,
          });
        });
        this.tblDeliveryRateOptions = [];
        this.$store.getters.deliveryRates.map((qData) => {
          this.tblDeliveryRateOptions.push({
            value: qData.name,
            label: qData.name,
          });
        });
        this.columnDefs.push(
          {
            sortable: true,
            filter: true,
            field: "name",
            headerName: "Name",
            editable: false,
          },
          {
            sortable: true,
            filter: true,
            field: "period",
            headerName: "Period",
            editable: true,
          },
          {
            sortable: true,
            filter: true,
            field: "rate",
            headerName: "Rate",
            editable: true,
          },
          {
            sortable: false,
            filter: false,
            field: "action",
            headerName: "Action",
            editable: false,
            type: "link",
            formatter: displayActionButton,
          }
        );
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async deleteDeliveryRate(event) {
      try {
        console.log("deleteDeliveryRate", event)
        this.$bvModal
          .msgBoxConfirm(
            `Please confirm that you want to delete this Delivery Rate ${event.name}.`,
            {
              title: "Delete Delivery Rate",
              okVariant: "danger",
              okTitle: "Yes",
              cancelTitle: "No",
              footerClass: "p-2",
              hideHeaderClose: false,
            }
          )
          .then(async (status) => {
            if (status) {
              const response = await this.axios({
                method: "DELETE",
                url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates/${event.id}`,
                headers: {
                  Authorization: `Bearer ${this.$store.getters.idToken}`,
                },
                data: {
                  name: event.name,
                },
              });
              this.displayMessage("success", response.data["success"]);
              await this.getAllDeliveryRates();
            }
          });
      } catch (errorResponse) {
        this.errorDeleteDeliveryRate();
        console.error("Error: ", errorResponse);
      }
    },
    async updateDeliveryRate() {
      console.log("purarray", this.putRequestArray)
      const totalUpdateRecords = this.putRequestArray.length;
      if (totalUpdateRecords == 0) {
        this.displayMessage(
            "warning",
            "Alert! There is no available changes for update"
        );
      } else {
        let updateCodesString = "";
        this.putRequestArray.map(async (qData) => {
          console.log("qData", qData)
          updateCodesString += `${qData.name}, `;
        });
        this.$bvModal
          .msgBoxConfirm(
            `Please confirm that you want to update all of the changes that you have already done on Zone Delivery Rates: ${updateCodesString}`,
            {
              title: "Update Zone Delivery Rate Records",
              okVariant: "primary primary-btn",
              okTitle: "Yes",
              cancelTitle: "No",
              footerClass: "p-2",
              hideHeaderClose: false,
            }
          )
          .then(async (status) => {
            if (status) {
              try {
                if (totalUpdateRecords > 1) {
                  let response;

                  this.putRequestArray.map(async (qData) => {
                    console.log("qData", qData)
                    response = await this.axios({
                      method: "PUT",
                      url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates/${qData.id}`,
                      headers: {
                        Authorization: `Bearer ${this.$store.getters.idToken}`,
                      },
                      data: {
                        "id": qData.id,
                        "name": qData.name,
                        "period": qData.period,
                        "rate": qData.rate
                      },
                    });
                    this.displayMessage("success", response.data["success"]);
                    this.isUpdateDisabled = true;
                  });
                } else {
                  const response = await this.axios({
                    method: "PUT",
                    url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates/${this.putRequestArray[0]["id"]}`,
                    headers: {
                      Authorization: `Bearer ${this.$store.getters.idToken}`,
                    },
                    data: {
                      "id": this.putRequestArray[0]["id"],
                      "name": this.putRequestArray[0]["name"],
                      "period": this.putRequestArray[0]["period"],
                      "rate": this.putRequestArray[0]["rate"]
                    }
                  });
                  this.displayMessage("success", response.data["success"]);
                  this.isUpdateDisabled = true;
                }
                await this.getAllDeliveryRates();
                this.putRequestArray = [];
              } catch (errorResponse) {
                this.errorUpdateDeliveryRate();
                console.error("Error: ", errorResponse);
              }
            }
          });
      }
    },
    async getAllDeliveryRatesByPagination(currentPage) {
      const offset = this.pgPerPage * (currentPage - 1);
      this.rowData = this.totalRowData.slice(offset, offset + this.pgPerPage);
      this.loopRowdata();
    },
    async getAllDeliveryRates() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`,
        },
      });
      this.pgTotalRows = parseInt(response.data.length);
      /**convert applicableStartTime and applicableEndTime to datatime */
      const today = this.getNowFormatDate();
      for (const data of response.data) {
        if (data.applicableStartTime) {
          data.applicableStartTime = today + "T" + data.applicableStartTime + ".000Z";
        }
        if (data.applicableEndTime) {
          data.applicableEndTime = today + "T" + data.applicableEndTime + ".000Z";
        }
      }
      this.totalRowDataNotFilter = response.data;
      this.totalRowData = response.data;
      this.rowData = this.totalRowData.slice(0, this.pgPerPage);
      this.loopRowdata();
    },
    getNowFormatDate() {
      const date = new Date();
      const seperator1 = "-";
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      const currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    showAddModal() {
      this.$refs.AddDeliveryRateModal.show();
    },
    setUpdateRowArray(event) {
      console.log("event",event)
      this.isUpdateDisabled = false;
      this.putRequestArray = [];
      event.map((qData) => {
        this.putRequestArray.push({
          'id': qData.id,
          'name': qData.name,
          'rate': qData.rate,
          'period': qData.period
        });
      });
    },
    async refreshGrid(message) {
      await this.getAllDeliveryRates();
      this.displayMessage("success", message);
    },
    errorCreateDeliveryRate() {
      this.displayMessage(
        "warning",
        "Delivery Rate saving process failed."
      );
    },
    errorUpdateDeliveryRate() {
      this.displayMessage(
        "warning",
        "Zone Delivery Rate update process failed."
      );
    },
    errorDeleteZoneDeliveryRate() {
      this.displayMessage(
        "warning",
        "Zone Delivery Rate delete process failed."
      );
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true,
      });
    },
    loopRowdata() {
      this.rowData.forEach(d => {
        d['$cellStyle'] =  {
          'rate': { backgroundColor: '#e7eef2' },
          'period': { backgroundColor: '#e7eef2' },
          'action': { justifyContent: 'center' }
        }
      })
    }
  },
};
</script>
