<template>
  <nav class="navbar navbar-expand navbar-light navbar-bg">
    <a class="sidebar-toggle d-flex">
      <i @click="hideLeftMenu" class="hamburger align-self-center"></i>
    </a>
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav navbar-align">
        <!-- <li class="nav-item dropdown">
          <notification-dropdown></notification-dropdown>
        </li> -->
        <!-- <li class="nav-item dropdown">
          <message-dropdown></message-dropdown>
        </li> -->
        <li class="nav-item d-flex align-items-center h-100">
          <token-expiration-warning
            v-if="tokenExpirationWarning.show"
            :time-left="tokenExpirationWarning.timeLeft"
            @refresh-session="handleRefreshSession"
            class="h-100 d-flex align-items-center"
          />
        </li>
        <li class="nav-item dropdown">
          <user-dropdown></user-dropdown>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
// import NotificationDropDown from "@/components/notification-dropdown.vue";
// import MessageDropDown from "@/components/message-dropdown.vue";
import UserDropDown from "@/components/user-dropdown.vue";
import TokenExpirationWarning from "@/components/token-expiration-warning.vue";
import { eventBus } from "../../main";
import { mapState, mapActions } from "vuex";

export default {
  data: function() {
    return {
      hidenNavMenu: false
    };
  },
  components: {
    // "notification-dropdown": NotificationDropDown,
    // "message-dropdown": MessageDropDown,
    "user-dropdown": UserDropDown,
    "token-expiration-warning": TokenExpirationWarning
  },
  computed: {
    ...mapState(["tokenExpirationWarning"])
  },
  methods: {
    ...mapActions(["refreshToken"]),
    hideLeftMenu() {
      this.hidenNavMenu = !this.hidenNavMenu;
      eventBus.hideLeftMenu(this.hidenNavMenu);
    },
    async handleRefreshSession() {
      try {
        // Call the refreshToken action and get the new token
        const result = await this.refreshToken({
          oktaAuth: this.$auth.oktaAuth
        });

        // Get the new expiration time from the refreshed token
        const tokenExpiration = await this.$auth.oktaAuth.tokenManager.get(
          "accessToken"
        );
        const newExpirationTime = tokenExpiration.expiresAt * 1000 - Date.now();

        // Return the new expiration time to update the warning component
        return { newExpirationTime };
      } catch (error) {
        console.error("Failed to refresh token:", error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/navbar.scss";
@import "@/assets/scss/avatar.scss";
@import "@/assets/scss/hamburger.scss";

.navbar {
  height: 64px;
}

.nav-item {
  height: 100%;
}
</style>
